var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "slide-time-tracking-bar" } }, [
        _vm.show_tracking_bar
          ? _c("div", { key: "bar", staticClass: "bar" }, [
              _c(
                "div",
                { staticClass: "medium_wrapper" },
                [
                  _vm.active_session || _vm.is_loading_session
                    ? _c("tracking-block", {
                        attrs: {
                          user: _vm.user,
                          editable: true,
                          show_stop_button: "",
                        },
                      })
                    : _vm.selected_session
                    ? _c("session-detail", {
                        directives: [
                          {
                            name: "on-clickaway",
                            rawName: "v-on-clickaway",
                            value: _vm.deselectSession,
                            expression: "deselectSession",
                          },
                        ],
                        attrs: { session: _vm.selected_session },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }