var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "todo-list" },
    [
      _c(
        "content-block",
        { attrs: { title: _vm.todosTitle, no_background: "" } },
        [
          _c(
            "template",
            { slot: "title_content_suffix" },
            [
              _vm.is_atlassian_authed && _vm.jira_loading
                ? _c("loading-icon", { attrs: { icon: "jira" } })
                : _vm._e(),
              _vm.is_atlassian_authed && _vm.confluence_loading
                ? _c("loading-icon", { attrs: { icon: "confluence" } })
                : _vm._e(),
            ],
            1
          ),
          _c("template", { slot: "right" }, [
            _vm.is_atlassian_authed
              ? _c("span", { staticClass: "jira-info" }, [
                  _vm._v(" Backlog issues are hidden. "),
                ])
              : _vm._e(),
          ]),
          _vm.$store.getters.is_feature_enabled(_vm.SUPPORTED_FEATURES.TODOS)
            ? _c("todo-input", { attrs: { force_project_id: _vm.project?.id } })
            : _vm._e(),
          _vm.jira_forbidden
            ? _c("el-alert", {
                staticClass: "jira_forbidden_alert",
                attrs: {
                  description:
                    "You do not have access to this project's jira board.",
                  closable: false,
                },
              })
            : _vm._e(),
          _vm.has_nothing && !_vm.loading
            ? _c("placeholder", [
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("You smashed all your tasks!"),
                ]),
              ])
            : _vm._e(),
          _vm._l(_vm.grouped_items, function (group, idx) {
            return _c("todo-section", {
              key: `section_${idx}`,
              attrs: {
                todos: group.todos,
                tasks: group.tasks,
                issues: group.issues,
                label: group.name,
                project_ref: group.project_ref,
              },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }