var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("label", [
      _vm._v(" Atlassian "),
      !_vm.is_atlassian_authed
        ? _c(
            "a",
            {
              attrs: {
                href:
                  "https://id.atlassian.com/manage-profile/security/api-tokens",
                target: "_blank",
              },
            },
            [_vm._v(" Generate your token ")]
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "right" },
      [
        _vm.loading
          ? _c(
              "el-button",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: true,
                    expression: "true",
                  },
                ],
                staticClass: "spinner",
                attrs: {
                  disabled: "",
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "rgba(0, 0, 0, 0)",
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-loading",
                  staticStyle: { visibility: "hidden" },
                }),
              ]
            )
          : [
              _vm.is_atlassian_authed
                ? [
                    _c("el-button", { attrs: { disabled: "" } }, [
                      _vm._v(" Connected "),
                    ]),
                    _c("el-button", {
                      staticStyle: { "flex-grow": "inherit" },
                      attrs: { icon: "el-icon-delete" },
                      on: { click: _vm.handleDisconnectAtlassian },
                    }),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            placeholder: "Email",
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.atlassian_api_user,
                            callback: function ($$v) {
                              _vm.atlassian_api_user = $$v
                            },
                            expression: "atlassian_api_user",
                          },
                        }),
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            placeholder: "API token",
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.atlassian_api_token,
                            callback: function ($$v) {
                              _vm.atlassian_api_token = $$v
                            },
                            expression: "atlassian_api_token",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled:
                            !_vm.atlassian_api_user || !_vm.atlassian_api_token,
                        },
                        on: { click: _vm.handleConnectAtlassian },
                      },
                      [_vm._v(" Connect ")]
                    ),
                  ],
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }