var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "content-block",
      class: {
        collapsed: !_vm.is_expanded,
        collapsable: _vm.collapsable,
        no_background: _vm.no_background,
        outlined: _vm.outlined,
      },
    },
    [
      _vm.title
        ? _c(
            "el-row",
            { staticClass: "head" },
            [
              _c(
                "el-col",
                { staticClass: "left-col", attrs: { lg: 12, sm: 24 } },
                [
                  _vm._t("title_content_prefix"),
                  _c("label", [_vm._v(_vm._s(_vm.title))]),
                  _vm._t("title_content_suffix"),
                ],
                2
              ),
              _vm.$slots.right
                ? _c(
                    "el-col",
                    { staticClass: "right-col", attrs: { lg: 12, sm: 24 } },
                    [
                      _c(
                        "div",
                        { class: { collapsable: _vm.collapsable } },
                        [_vm._t("right")],
                        2
                      ),
                      _vm.collapsable
                        ? _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.is_expanded = !_vm.is_expanded
                                },
                              },
                            },
                            [
                              _c("svgicon", {
                                staticClass: "arrow",
                                attrs: { name: "triangle" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.is_expanded ? _vm._t("default") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }