var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notifications" }, [
    _vm.notifications.length > 0
      ? _c("ul", { staticClass: "actions" }, [
          _c("li", [
            _c("a", { attrs: { href: "#" }, on: { click: _vm.clearAll } }, [
              _vm._v("Clear all"),
            ]),
          ]),
          _c("li", [
            _c(
              "a",
              { attrs: { href: "#" }, on: { click: _vm.markAllAsRead } },
              [_vm._v("Mark all as Read")]
            ),
          ]),
        ])
      : _vm._e(),
    _c(
      "ul",
      [
        _vm.notifications.length > 0
          ? _vm._l(_vm.notifications, function (notification) {
              return _c(
                "li",
                { key: notification.id },
                [
                  _c("notification-list-item", {
                    attrs: { notification: notification },
                    on: { "click:notification": _vm.handleClick },
                  }),
                ],
                1
              )
            })
          : [
              _c("li", { staticClass: "placeholder" }, [
                _vm._v(" No notifications! "),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }