var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "long-press",
          rawName: "v-long-press",
          value: 300,
          expression: "300",
        },
      ],
      staticClass: "checkbox",
      class: {
        disable: !_vm.clickable,
        editing: _vm.show_priority,
      },
      on: {
        "long-press-start": _vm.onLongPressStart,
        "long-press-stop": _vm.onLongPressStop,
      },
    },
    [
      _c("input", {
        attrs: {
          id: _vm.id,
          type: "checkbox",
          name: "checkbox",
          value: "checkbox",
          disabled: _vm.show_priority,
        },
        domProps: { checked: _vm.checked },
        on: { change: _vm.clicked },
      }),
      _c(
        "label",
        { class: { show_priority: _vm.show_priority }, attrs: { for: _vm.id } },
        [
          _c(
            "svg",
            {
              staticClass: "svgIcon detailsTitle-checkmarkSvg",
              attrs: { viewBox: "0 0 32 32", title: "checkmark" },
            },
            [
              _c("polygon", {
                attrs: {
                  points:
                    "27.672,4.786 10.901,21.557 4.328,14.984 1.5,17.812 10.901,27.214 30.5,7.615 ",
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "priority", class: { show: _vm.show_priority } },
        [
          _c(
            "div",
            {
              staticClass: "cursor",
              style: { bottom: _vm.cursor_position + "px" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.priority))])]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }