var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "link",
      attrs: { href: _vm.link, target: "_blank" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return (() => null).apply(null, arguments)
        },
      },
    },
    [_vm._v(" XERO " + _vm._s(_vm.invoices) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }