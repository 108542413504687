var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.custom_component
    ? _c(_vm.custom_component, {
        tag: "component",
        attrs: { show_back: _vm.show_back, initial_data: _vm.active_data },
        on: { goBack: _vm.handleGoBack, close: _vm.handleClose },
      })
    : _c(
        "command-layout",
        {
          attrs: { show_back: _vm.show_back },
          on: { goBack: _vm.handleGoBack },
        },
        [
          _c(
            "div",
            {
              staticClass: "header",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("div", { staticClass: "command-prompt" }, [
                _vm._v(_vm._s(_vm.prompt)),
              ]),
              _c("el-input", {
                ref: "search",
                staticClass: "search",
                attrs: { disabled: !!_vm.loading, size: "small" },
                nativeOn: {
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return (() => _vm.addData()).apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "backspace",
                          undefined,
                          $event.key,
                          undefined
                        )
                      )
                        return null
                      return (() => _vm.handleBackspacePress()).apply(
                        null,
                        arguments
                      )
                    },
                  ],
                },
                model: {
                  value: _vm.search_string,
                  callback: function ($$v) {
                    _vm.search_string = $$v
                  },
                  expression: "search_string",
                },
              }),
              _c(
                "div",
                { staticClass: "breadcrumbs" },
                _vm._l(_vm.breadcrumbs, function (crumb, index) {
                  return _c(
                    "span",
                    { key: index },
                    [
                      _c("svgicon", {
                        staticClass: "breadcrumb-arrow",
                        attrs: { name: "triangle" },
                      }),
                      _vm._v(" " + _vm._s(crumb) + " "),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: !!_vm.loading,
                  expression: "!!loading",
                },
              ],
              staticClass: "body",
              attrs: { slot: "body", "element-loading-text": _vm.loading },
              slot: "body",
            },
            [
              _vm.provider_results.length
                ? _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "shortkey",
                          rawName: "v-shortkey",
                          value: {
                            up: ["arrowup"],
                            down: ["arrowdown"],
                            pageup: ["pageup"],
                            pagedown: ["pagedown"],
                            top: ["home"],
                            bottom: ["end"],
                          },
                          expression:
                            "{\n                up: ['arrowup'],\n                down: ['arrowdown'],\n                pageup: ['pageup'],\n                pagedown: ['pagedown'],\n                top: ['home'],\n                bottom: ['end'],\n            }",
                        },
                      ],
                      staticClass: "results",
                      on: { shortkey: _vm.selectItem },
                    },
                    _vm._l(_vm.filtered_results, function (provider) {
                      return _c(
                        "li",
                        { key: provider.provides, staticClass: "provider" },
                        [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(" " + _vm._s(provider.label) + " "),
                            provider.results === undefined
                              ? _c("i", { staticClass: "el-icon-loading" })
                              : _vm._e(),
                          ]),
                          provider.results === null
                            ? _c("span", { staticClass: "info" }, [
                                _vm._v(" type a filter string to view "),
                              ])
                            : _c(
                                "ul",
                                _vm._l(provider.results, function (result) {
                                  return _c(
                                    "li",
                                    {
                                      key: result.id,
                                      ref: result.id,
                                      refInFor: true,
                                      staticClass: "result",
                                      class: {
                                        selected:
                                          _vm.selected_item &&
                                          result.id === _vm.selected_item.id,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return (() =>
                                            _vm.addData(result)).apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      result.shortcut
                                        ? _c(
                                            "el-tag",
                                            {
                                              staticClass: "shortcut",
                                              attrs: {
                                                type: "info",
                                                size: "mini",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(result.shortcut) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", { staticClass: "label" }, [
                                        _vm._v(
                                          " " + _vm._s(result.label) + " "
                                        ),
                                      ]),
                                      _c("span", { staticClass: "sub-label" }, [
                                        _vm._v(
                                          " " + _vm._s(result.sub_label) + " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "no-results" }, [
                    _vm._v("No matching results"),
                  ]),
              _c(_vm.active_command, {
                ref: "command",
                tag: "component",
                attrs: {
                  provided_data: _vm.active_data,
                  search_string: _vm.search_string,
                },
                on: {
                  command: _vm.changeCommand,
                  component: _vm.showCustomComponent,
                  loading: _vm.setLoading,
                  close: _vm.handleClose,
                },
              }),
            ],
            1
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }