var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      attrs: {
        "open-delay": 200,
        placement: "bottom",
        "popper-class": "top-tooltip",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "tooltip-content",
          attrs: { slot: "content" },
          slot: "content",
        },
        [_vm._v(" Search "), _c("span", [_vm._v("cmd + k")])]
      ),
      _c(
        "div",
        {
          staticClass: "search",
          on: {
            click: function ($event) {
              return _vm.$bus.$emit("modal:action", {
                modal: "command-palette",
                show: true,
              })
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-search",
            attrs: { slot: "prepend" },
            slot: "prepend",
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }