var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.show, width: "45%", "show-close": false },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        closed: _vm.handleCloseDialog,
      },
    },
    [
      _c("command-palette", {
        attrs: {
          command: _vm.command,
          initial_data: _vm.command_data,
          showing: _vm.show,
        },
        on: { close: _vm.handleCloseDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }