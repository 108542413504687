var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("button", { on: { click: _vm.onClick } }, [
    _c("i", { staticClass: "el-icon-plus" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }