var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.project
    ? _c(
        "el-select",
        {
          ref: "select",
          attrs: {
            filterable: "",
            "filter-method": _vm.filterComponents,
            value: _vm.component,
            placeholder: _vm.placeholder,
            "value-key": "id",
            size: "mini",
          },
          on: { change: (val) => _vm.$emit("change", val) },
        },
        [
          _c(
            "el-option-group",
            {
              key: "highlighted",
              staticClass: "highlighted-option-group",
              attrs: { label: `Highlighted:` },
            },
            _vm._l(_vm.highlighted_components, function (c) {
              return _c(
                "el-option",
                { key: c.id, attrs: { label: `${c.name}`, value: c } },
                [
                  _c("span", { staticClass: "component-name" }, [
                    _vm._v(" " + _vm._s(c.name) + " "),
                  ]),
                  c.ref
                    ? _c("span", { staticClass: "component-ref" }, [
                        _vm._v(" " + _vm._s(c.ref) + " "),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
          _vm._l(_vm.stages, function (s) {
            return _c(
              "el-option-group",
              {
                key: s.id,
                staticClass: "stage-option-group",
                class: s.status,
                attrs: {
                  label: `Stage #${s.number}${s.notes ? ` - ${s.notes}` : ""}`,
                },
              },
              _vm._l(s._components, function (c) {
                return _c(
                  "el-option",
                  {
                    key: c.id,
                    staticClass: "component-option",
                    class: c.status,
                    attrs: {
                      label: `${c.name} - Stage #${s.number}`,
                      value: c,
                    },
                  },
                  [
                    _c("span", { staticClass: "component-name" }, [
                      _vm._v(" " + _vm._s(c.name) + " "),
                    ]),
                    c.ref
                      ? _c("span", { staticClass: "component-ref" }, [
                          _vm._v(" " + _vm._s(c.ref) + " "),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }