var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "link",
      attrs: { href: _vm.link, target: "_blank" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return null.apply(null, arguments)
        },
      },
    },
    [
      _c("svgicon", {
        staticClass: "confluence_icon",
        attrs: { name: "confluence" },
      }),
      _vm._v(" " + _vm._s(_vm.title) + " "),
      _c(
        "el-tag",
        { staticClass: "space", attrs: { size: "mini", type: "info" } },
        [_vm._v(_vm._s(_vm.space))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }