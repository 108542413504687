var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "todo-item-content",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(
        "p",
        { staticClass: "wrapper" },
        _vm._l(_vm.text_parts, function (chunk, idx) {
          return _c(
            chunk.component,
            _vm._b({ key: idx, tag: "component" }, "component", chunk, false)
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }