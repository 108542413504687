var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.component
    ? _c(_vm.component, {
        tag: "component",
        attrs: {
          id: _vm.id,
          link: _vm.link,
          url: _vm.url,
          resolved: _vm.resolved,
        },
      })
    : _c(
        "a",
        {
          staticClass: "link",
          attrs: { href: "javascript:void(0)" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClick.apply(null, arguments)
            },
          },
        },
        [
          _c("img", { staticClass: "favicon", attrs: { src: "/favicon.ico" } }),
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }