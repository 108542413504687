var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "center-container medium_wrapper" },
      [
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [_c("router-view")],
          1
        ),
      ],
      1
    ),
    _c("footer", [
      _c(
        "span",
        { staticClass: "signature" },
        [
          _vm._v(" Made with "),
          _c("heart", { attrs: { width: 8 } }),
          _vm._v(" by CodeFish Studio "),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }