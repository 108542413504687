var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "facepile", class: { has_time_data: _vm.time_data } },
    [
      _c(
        "div",
        { staticClass: "facepile-inner" },
        [
          _c(
            "transition-group",
            { attrs: { name: "slide-face-pile", tag: "div" } },
            _vm._l(_vm.users, function (user) {
              return _c(
                "div",
                { key: user.id, staticClass: "group" },
                [
                  _c("avatar", {
                    attrs: {
                      user: user,
                      size: _vm.size,
                      hide_tooltip: _vm.hide_tooltip,
                    },
                  }),
                  _vm.time_data
                    ? _c("span", { staticClass: "time_spent" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("milli2duration")(_vm.time_data[user.id])
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }