var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "time-indicator desktop-only",
      on: {
        mouseover: function ($event) {
          _vm.disable_hover ? null : (_vm.hover = true)
        },
        mouseleave: function ($event) {
          _vm.disable_hover ? null : (_vm.hover = false)
        },
      },
    },
    [
      _vm.hover && _vm.time_estimate
        ? _c("span", { staticClass: "time" }, [
            _c("span", [
              _c("strong", [
                _vm._v(_vm._s(_vm._f("milli2duration")(_vm.remaining))),
              ]),
            ]),
            _c("span", [_vm._v("left")]),
          ])
        : _c("div", { staticClass: "circle" }, [
            _c(
              "div",
              { staticClass: "inner", style: { backgroundColor: _vm.color } },
              [_c("div", { staticClass: "ring", class: _vm.ring_class })]
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }