var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$store.getters.is_feature_enabled(
    _vm.SUPPORTED_FEATURES.GOOGLE_AUTH
  )
    ? _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show || _vm.show_password_dialog_override,
            width: "45%",
            "close-on-click-modal": _vm.can_close,
            "close-on-press-escape": _vm.can_close,
            "show-close": _vm.can_close,
          },
          on: {
            close: _vm.onCloseDialog,
            "update:show": (val) => (_vm.show = val),
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [
                    _c("label", [_vm._v(" Change your password ")]),
                    _c("hr"),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            669446095
          ),
        },
        [
          _c(
            "el-alert",
            {
              staticClass: "warning",
              attrs: { type: "error", closable: false },
            },
            [
              _vm._v(
                " After changing your password, you will be required to log in again. "
              ),
            ]
          ),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form-wrapper",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "New Password", prop: "new_password" } },
                [
                  _c("el-input", {
                    attrs: {
                      name: "New Password",
                      type: "password",
                      "auto-complete": "off",
                      placeholder: "New password",
                    },
                    on: { change: _vm.validatePasswords },
                    model: {
                      value: _vm.form.new_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "new_password", $$v)
                      },
                      expression: "form.new_password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Confirm Password",
                    prop: "confirm_password",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      name: "Confirm Password",
                      type: "password",
                      "auto-complete": "off",
                      placeholder: "Confirm password",
                    },
                    on: { change: _vm.validatePasswords },
                    model: {
                      value: _vm.form.confirm_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "confirm_password", $$v)
                      },
                      expression: "form.confirm_password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "create-button",
                  attrs: { disabled: _vm.updating },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" Update Password ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }