var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "breadcrumb" }, [
    _c(
      "div",
      { staticClass: "medium_wrapper row" },
      [
        _c("transition", { attrs: { name: "slide-back" } }, [
          _vm.can_go_back
            ? _c(
                "div",
                { staticClass: "back", on: { click: _vm.goBackAction } },
                [
                  _c("svgicon", {
                    staticClass: "triangle",
                    attrs: { name: "triangle" },
                  }),
                  _c("label", [_vm._v("BACK "), _c("span", [_vm._v("/")])]),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("transition", { attrs: { name: "slide-title", mode: "out-in" } }, [
          _vm.item
            ? _c(
                "div",
                {
                  key: _vm.item.meta.title || _vm.remote_title,
                  staticClass: "title",
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.item.meta.title || _vm.remote_title)),
                  ]),
                  _vm.item.meta.ref
                    ? _c("span", { staticClass: "ref" }, [
                        _vm._v(" [" + _vm._s(_vm.item.meta.ref) + "] "),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }