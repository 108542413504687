var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.todos.length || _vm.issues.length
    ? _c("div", [
        _vm.label
          ? _c(
              "label",
              {
                staticClass: "section-title",
                class: { clickable: !!_vm.project_ref },
                on: { click: _vm.clickOnLabel },
              },
              [
                _vm.project_ref
                  ? [
                      _c("span", [_vm._v(_vm._s(_vm.project_ref))]),
                      _vm._v(" - "),
                    ]
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.label) + ": "),
              ],
              2
            )
          : _vm._e(),
        _c(
          "ul",
          { staticClass: "section" },
          [
            _vm._l(_vm.todos, function (item) {
              return _c("todo-item", {
                key: `todo_${item.id}-${item.status}`,
                attrs: {
                  item: item,
                  show_user: _vm.show_user,
                  show_project: _vm.show_project_on_items,
                  show_priority: _vm.show_priority_on_items,
                },
              })
            }),
            _vm._l(_vm.tasks, function (task) {
              return _c("todo-item", {
                key: `task_${task.id}-${task.status}`,
                attrs: {
                  item: task,
                  show_user: _vm.show_user,
                  show_project: _vm.show_project_on_items,
                },
              })
            }),
            _vm._l(_vm.issues, function (issue) {
              return _c("issue-item", {
                key: `issue_${issue.project.jira_project}_${issue.id}`,
                attrs: {
                  item: issue,
                  show_user: _vm.show_user,
                  show_project: _vm.show_project_on_items,
                },
              })
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }