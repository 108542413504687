var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.image
    ? _c("img", { attrs: { src: _vm.image, draggable: "false" } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }