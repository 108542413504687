var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "player", attrs: { shadow: "never" } },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col", staticStyle: { flex: "1" } },
              [
                _vm.show_name
                  ? _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.group.name)),
                    ])
                  : _vm._e(),
                _vm.is_playing
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("stop-button", {
                          attrs: { loading: _vm.loading.pause },
                          nativeOn: {
                            click: function ($event) {
                              return (() => _vm.changeState("pause")).apply(
                                null,
                                arguments
                              )
                            },
                          },
                        }),
                        _c("skip-button", {
                          attrs: { loading: _vm.loading.skipToNext },
                          nativeOn: {
                            click: function ($event) {
                              return (() =>
                                _vm.changeState("skipToNext")).apply(
                                null,
                                arguments
                              )
                            },
                          },
                        }),
                        _c("el-slider", {
                          attrs: {
                            step: 1,
                            min: 0,
                            max: 100,
                            "show-tooltip": false,
                          },
                          on: { change: (v) => _vm.changeVolume(v) },
                          model: {
                            value: _vm.volume_level,
                            callback: function ($$v) {
                              _vm.volume_level = $$v
                            },
                            expression: "volume_level",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("play-button", {
                      attrs: { loading: _vm.is_buffering || _vm.loading.play },
                      nativeOn: {
                        click: function ($event) {
                          return (() => _vm.changeState("play")).apply(
                            null,
                            arguments
                          )
                        },
                      },
                    }),
              ],
              1
            ),
            _vm.current_track
              ? [
                  _c("div", { staticClass: "track-info col right" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.current_track.title)),
                    ]),
                    _c("span", { staticClass: "artist" }, [
                      _vm._v(" " + _vm._s(_vm.current_track.artist) + " "),
                    ]),
                    _c("span", { staticClass: "album" }, [
                      _vm._v(_vm._s(_vm.current_track.album)),
                    ]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }