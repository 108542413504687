var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "node-view-wrapper",
    { staticClass: "link" },
    [
      !_vm.editing
        ? _c("pretty-link", { attrs: { link: _vm.node.attrs.url } })
        : _c("el-input", {
            attrs: { size: "mini" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.saveContent.apply(null, arguments)
              },
            },
            model: {
              value: _vm.text,
              callback: function ($$v) {
                _vm.text = $$v
              },
              expression: "text",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }