var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === _vm.URL_TYPE.JIRA
    ? _c("jira-link", { attrs: { link: _vm.link, show_summary: "" } })
    : _vm.type === _vm.URL_TYPE.CONFLUENCE
    ? _c("confluence-link", { attrs: { link: _vm.link } })
    : _vm.type === _vm.URL_TYPE.TOOL
    ? _c("tool-link", { attrs: { link: _vm.link, url: _vm.url } })
    : _vm.type === _vm.URL_TYPE.XERO
    ? _c("xero-link", { attrs: { link: _vm.link } })
    : _c(
        "a",
        {
          staticClass: "link",
          attrs: { href: _vm.link, target: "_blank" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _vm.display_favicon
            ? _c("img", {
                staticClass: "favicon",
                attrs: { src: _vm.favicon },
                on: { error: _vm.fallbackIcon },
              })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }