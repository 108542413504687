var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "issue-item-container" },
    [
      _vm.image_icon
        ? [
            _c("img", {
              staticClass: "icon",
              attrs: { alt: _vm.image_label, src: _vm.image_icon },
              on: { error: _vm.imgError },
            }),
          ]
        : [
            _c("svgicon", {
              staticClass: "icon",
              class: _vm.ISSUE_ICONS[_vm.ISSUE_CATEGORIES.TODO],
              attrs: { name: _vm.ISSUE_ICONS[_vm.ISSUE_CATEGORIES.TODO] },
            }),
          ],
      _c("span", { staticClass: "key" }, [
        _vm._v(_vm._s(_vm.data.key ?? "Todo")),
      ]),
      _vm.show_summary
        ? _c("span", { staticClass: "summary" }, [
            _vm._v(_vm._s(_vm.data.summary)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }