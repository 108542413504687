var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.todo_item && _vm.todo_item.id
    ? _c(
        "li",
        {
          directives: [
            {
              name: "on-clickaway",
              rawName: "v-on-clickaway",
              value: _vm.stopEditing,
              expression: "stopEditing",
            },
          ],
          ref: "todo",
          staticClass: "todo-item",
          class: {
            completed: _vm.is_completed,
            editing: _vm.is_editing,
            in_editor: _vm.in_editor,
            highlight: _vm.highlight,
          },
          on: {
            mouseenter: _vm.mouseover,
            mouseleave: _vm.mouseout,
            click: _vm.startEditing,
          },
        },
        [
          _c(
            "rendered-todo-item",
            {
              attrs: {
                todo_id: _vm.todo_item.id,
                is_checked: _vm.is_completed,
                priority: _vm.todo_item.priority,
              },
              on: {
                "status:toggle": _vm.toggleStatus,
                "priority:change": _vm.priorityChanged,
              },
            },
            [
              [
                _c("todo-priority", {
                  attrs: {
                    priority: _vm.todo_item.priority,
                    show:
                      !_vm.is_read_only &&
                      (_vm.show_priority || _vm.priority_changed),
                  },
                }),
                _vm.user && _vm.show_user && !_vm.todo_item.confluence
                  ? _c("span", { staticClass: "user" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleClickOnUser.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.user.name) + " ")]
                      ),
                    ])
                  : _vm._e(),
                _vm.project && _vm.show_project
                  ? _c("span", { staticClass: "project" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleClickOnProject.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.project.ref.toUpperCase()) + " "
                          ),
                        ]
                      ),
                      _vm._v(" - "),
                    ])
                  : _vm._e(),
                _c("todo-item-content", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.is_editing || _vm.is_read_only,
                      expression: "!is_editing || is_read_only",
                    },
                  ],
                  attrs: {
                    text: _vm.todo_item.original_text,
                    jira_project: _vm.jira_project,
                    jira_org: _vm.jira_org,
                  },
                }),
                _vm.is_editing && !_vm.is_read_only
                  ? [
                      _c("todo-input", {
                        ref: "todoInput",
                        staticClass: "todo-edit",
                        attrs: {
                          content: _vm.todo_item.original_text,
                          due_by: _vm.todo_item.due_by,
                          component: _vm.component,
                          estimate: _vm.todo_item.estimate,
                          selected_user: _vm.user,
                          return_only: true,
                          show_user: true,
                          show_project: false,
                          show_date_picker: true,
                          force_project_id: _vm.project ? _vm.project.id : null,
                        },
                        on: {
                          dirty: _vm.setDirty,
                          created: _vm.handleSaveTodo,
                          cancel: _vm.cancelEditing,
                          "updated:user": _vm.handleUpdateTodoUser,
                          "updated:due_by": _vm.handleUpdateTodoDate,
                          "updated:component": _vm.handleUpdateComponent,
                          "updated:estimate": _vm.handleUpdateEstimate,
                        },
                      }),
                      _vm.is_dirty
                        ? _c("span", { staticClass: "info" }, [
                            _vm._v(" ( Press enter to save ) "),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.todo_item.dependencies
                  ? _c("div", { staticClass: "dependencies" }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              Object.keys(_vm.todo_item.dependencies).length
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
            ],
            2
          ),
          _vm.todo_item.component && !_vm.is_editing
            ? _c("div", { staticClass: "date component" }, [
                _vm._v(" " + _vm._s(_vm.todo_item.component.name) + " "),
              ])
            : _vm._e(),
          _vm.component && !_vm.is_editing
            ? _c("div", { staticClass: "component" }, [
                _vm._v(" " + _vm._s(_vm.component.name) + " "),
              ])
            : _vm._e(),
          _vm.todo_item.due_by && !_vm.is_editing
            ? _c(
                "div",
                { staticClass: "date due-by", class: _vm.date_priority },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("relativeDate")(_vm.todo_item.due_by)) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.todo_item.estimate && !_vm.is_editing
            ? _c("div", { staticClass: "estimate" }, [
                _vm._v(" " + _vm._s(_vm.todo_item.estimate) + "h "),
              ])
            : _vm._e(),
          _vm.todo_item.confluence
            ? _c("div", { staticClass: "icon-container" }, [
                _c(
                  "a",
                  { attrs: { href: _vm.confluence_link, target: "_blank" } },
                  [
                    _c("svgicon", {
                      staticClass: "confluence_icon",
                      attrs: { name: "confluence" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm.has_source && !_vm.in_editor
            ? _c("div", { staticClass: "icon-container" }, [
                _c("i", {
                  staticClass: "source_icon",
                  class: _vm.source_icon,
                  attrs: { size: "mini" },
                  on: { click: _vm.handleNavigateSource },
                }),
              ])
            : _vm._e(),
          !_vm.is_read_only
            ? _c(
                "el-dropdown",
                {
                  staticClass: "options",
                  attrs: { trigger: "click", placement: "bottom-end" },
                  on: { command: _vm.runCommand },
                },
                [
                  _c("div", { staticClass: "down-icon" }, [
                    _c(
                      "svg",
                      {
                        staticClass: "icon",
                        attrs: { focusable: "false", viewBox: "0 0 24 24" },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M3.5,8.9c0-0.4,0.1-0.7,0.4-1C4.5,7.3,5.4,7.2,6,7.8l5.8,5.2l6.1-5.2C18.5,7.3,19.5,7.3,20,8c0.6,0.6,0.5,1.5-0.1,2.1 l-7.1,6.1c-0.6,0.5-1.4,0.5-2,0L4,10.1C3.6,9.8,3.5,9.4,3.5,8.9z",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "destructive",
                          attrs: { command: "delete" },
                        },
                        [_vm._v(" Delete ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }