var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "item",
      class: { selected: _vm.is_selected },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
        mouseenter: _vm.onMouseEnter,
      },
    },
    [
      _c("issue-item-compact", {
        attrs: { data: _vm.data, show_summary: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }