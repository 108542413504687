var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("navbar"),
      _c("breadcrumb"),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
          _c("time-tracking-bar"),
        ],
        1
      ),
      _c("settings-modal", {
        attrs: { show_dialog: _vm.show["settings-modal"] },
        on: {
          "update:show_dialog": function ($event) {
            return _vm.$set(_vm.show, "settings-modal", $event)
          },
        },
      }),
      _c("change-password-modal", {
        attrs: { show_dialog: _vm.show["change-password"] },
        on: {
          "update:show_dialog": function ($event) {
            return _vm.$set(_vm.show, "change-password", $event)
          },
        },
      }),
      _c("command-palette-modal", {
        directives: [
          {
            name: "shortkey",
            rawName: "v-shortkey",
            value: { meta: ["meta", "k"], ctrl: ["ctrl", "k"] },
            expression: "{meta: ['meta', 'k'], ctrl: ['ctrl', 'k']}",
          },
        ],
        attrs: { show_dialog: _vm.show["command-palette"] },
        on: {
          "update:show_dialog": function ($event) {
            return _vm.$set(_vm.show, "command-palette", $event)
          },
        },
        nativeOn: {
          shortkey: function ($event) {
            $event.preventDefault()
            return _vm.modalAction({
              modal: "command-palette",
              show: !_vm.show["command-palette"],
            })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }