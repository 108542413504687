var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      { staticClass: "fullscreen" },
      [
        _vm.loading
          ? _c("loader", { attrs: { message: _vm.message } })
          : _vm._e(),
        _c(
          "span",
          { staticClass: "copyright" },
          [
            _vm._v(" Made with "),
            _c("heart", { attrs: { width: 12 } }),
            _vm._v(" by CodeFish Studio "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }