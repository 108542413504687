var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svgicon", {
    staticClass: "heart",
    style: { width: _vm.width + "px", fill: _vm.color },
    attrs: { name: "heart", draggable: "false" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }