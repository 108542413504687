var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editor
    ? _c(
        "div",
        {
          staticClass: "editor session-input",
          class: { responsive: _vm.simple_responsive_design },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              !_vm.show_editor && _vm.bound_tasks.length
                ? [
                    _vm.bound_tasks.length < 3
                      ? _c("add-issue-tag-button", {
                          on: { click: _vm.showEditor },
                        })
                      : _vm._e(),
                  ]
                : _c("editor-content", {
                    directives: [
                      {
                        name: "shortkey",
                        rawName: "v-shortkey.avoid",
                        modifiers: { avoid: true },
                      },
                    ],
                    ref: "editor_content",
                    attrs: { editor: _vm.editor },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.handleKeyUp.apply(null, arguments)
                      },
                    },
                  }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "bound_tasks" },
            _vm._l(_vm.bound_tasks, function (task) {
              return _c("issue-tag", {
                key: task.key,
                attrs: { data: task, show_summary: _vm.bound_tasks.length < 3 },
                on: {
                  click: () => _vm.onClickTask(task),
                  remove: () => _vm.unbindTask(task.ref),
                },
              })
            }),
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }