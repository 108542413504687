var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notification",
      class: { read: _vm.notification.read },
      on: { click: _vm.handleNavigate },
    },
    [
      _c("face-pile", {
        attrs: { users: _vm.users, size: "mini", hide_tooltip: "" },
      }),
      _c("div", { staticClass: "content" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.notification.title)),
        ]),
        _c(
          "span",
          { staticClass: "description" },
          [
            _c("todo-item-content", {
              attrs: { text: _vm.notification.description },
            }),
          ],
          1
        ),
        _c("span", { staticClass: "timestamp" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("timeAgo")(_vm.notification.created_at.toDate())) +
              " "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }