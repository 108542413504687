var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "priority", class: { show: _vm.show } }, [
    _vm._v(" " + _vm._s(_vm.priority ? _vm.priority : null) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }