var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { show_tracking_bar: _vm.show_tracking_bar },
      attrs: { id: "app" },
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_vm.loading ? _c("fullscreen-loader") : _c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }