var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "color-picker" },
    [
      _vm._t("prepend"),
      _c("div", {
        staticClass: "color",
        style: { "--color": _vm.value },
        on: { click: _vm.togglePicker },
      }),
      _vm.show
        ? _c(
            "div",
            { staticClass: "picker" },
            _vm._l(_vm.colors, function (color) {
              return _c("div", {
                key: color,
                staticClass: "color",
                style: { "--color": color },
                on: { click: () => _vm.handleSelect(color) },
              })
            }),
            0
          )
        : _vm._e(),
      _c("span", { staticClass: "append" }, [_vm._t("append")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }