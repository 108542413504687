var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide", mode: "out-in" } }, [
    _vm.component
      ? _c(
          "div",
          { key: _vm.component.id, staticClass: "component-info row" },
          [
            _c("time-indicator", { attrs: { component: _vm.component } }),
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "primary", on: { click: _vm.gotoProject } },
                [
                  _c("span", { staticClass: "ref" }, [
                    _vm._v(_vm._s(_vm.project.ref)),
                  ]),
                  _c("span", { staticClass: "spacer" }, [_vm._v("\\")]),
                  _c("span", { staticClass: "project" }, [
                    _vm._v(_vm._s(_vm.project.name)),
                  ]),
                ]
              ),
              _c("div", { staticClass: "secondary" }, [
                _c("span", { staticClass: "stage" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.stage ? `#${_vm.stage.number}` : null) +
                      " "
                  ),
                ]),
                _c("span", { staticClass: "spacer" }, [_vm._v("/")]),
                _c(
                  "div",
                  { staticClass: "component", on: { click: _vm.gotoActivity } },
                  [
                    _vm.component.ref
                      ? _c("span", { staticClass: "ref" }, [
                          _vm._v(" " + _vm._s(_vm.component.ref) + " "),
                        ])
                      : _vm._e(),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.component.name) + " "),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }