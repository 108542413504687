var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.filtered_integrations, function (integration, index) {
        return [
          _c(integration.component, {
            key: index,
            tag: "component",
            attrs: { show_dialog: _vm.show_dialog },
          }),
        ]
      }),
      !_vm.filtered_integrations.length
        ? [
            _c("el-alert", {
              staticClass: "placeholder",
              attrs: {
                description: "Looks like there are no integrations enabled.",
                closable: false,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }