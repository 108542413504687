var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loader", class: _vm.color }, [
    _c("div", {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: true,
          expression: "true",
        },
      ],
      staticClass: "spinner",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0)",
      },
    }),
    _c("span", [_vm._v(_vm._s(_vm.message))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }