var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "policy-settings" },
    _vm._l(_vm.user_policy_groups, function ([key, children]) {
      return _c("div", { key: key, staticClass: "container" }, [
        _c("label", [_vm._v(_vm._s(_vm._f("capitalize")(key)))]),
        _c(
          "div",
          { staticClass: "right" },
          _vm._l(Object.entries(children), function ([label, value]) {
            return _c(
              "div",
              { key: `${key}-${label}` },
              [
                _c("el-switch", {
                  attrs: {
                    "inactive-text": _vm.$options.filters.capitalize(label),
                    value: value,
                  },
                  on: {
                    change: (val) => _vm.updatePolicyValue(key, label, val),
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }