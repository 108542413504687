var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "command-layout" }, [
    _c("header", [
      _vm.show_back
        ? _c(
            "div",
            {
              staticClass: "back-button",
              on: {
                click: function ($event) {
                  return _vm.$emit("goBack")
                },
              },
            },
            [
              _c("svgicon", {
                staticClass: "arrow",
                attrs: { name: "triangle" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "content" }, [_vm._t("header")], 2),
    ]),
    _c("div", { staticClass: "content" }, [_vm._t("body")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }