var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "issue-tag",
      class: { dark: _vm.is_dark_mode },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c("issue-item-compact", {
        attrs: { data: _vm.data, show_summary: _vm.show_summary },
      }),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onRemove.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "el-icon-close" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }