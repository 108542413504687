var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("component-info", { attrs: { component: _vm.component } }),
      _c("div", { staticClass: "session-detail" }, [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "row" }, [
            _c("span", [_vm._v(" Start: ")]),
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("milli2date")(_vm.session.start.seconds * 1000)
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("span", [_vm._v(" Stop: ")]),
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("milli2date")(_vm.session.stop.seconds * 1000)
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "spacer" }),
        _c("span", { staticClass: "time" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("milli2duration")(
                  (_vm.session.stop.seconds - _vm.session.start.seconds) * 1000
                )
              ) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "note" },
          [
            _c("span", [_vm._v("What was this about?")]),
            _c("session-input", {
              staticClass: "el-input",
              attrs: { session: _vm.session },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }