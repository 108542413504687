var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uploader" },
    [
      _vm.uploadTask
        ? _c("el-progress", {
            attrs: { percentage: _vm.progressUpload, "show-text": false },
          })
        : _c("input", {
            ref: "fileupload",
            attrs: {
              type: "file",
              accept: "image/jpeg, image/png, image/svg+xml",
            },
            on: {
              change: function ($event) {
                return _vm.detectFile($event.target.files)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }