var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editor
    ? _c(
        "div",
        {
          staticClass: "editor",
          class: {
            responsive: _vm.simple_responsive_design,
            is_todo: _vm.is_todo,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
            },
          },
        },
        [
          _vm.has_prepend_slot
            ? _c("div", { staticClass: "prepend" }, [_vm._t("prepend")], 2)
            : _vm._e(),
          _vm.prefix_icon
            ? _c("div", { staticClass: "prefix-icon" }, [
                _c("i", { class: _vm.prefix_icon }),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("editor-content", {
                directives: [
                  {
                    name: "shortkey",
                    rawName: "v-shortkey.avoid",
                    modifiers: { avoid: true },
                  },
                ],
                ref: "editor_content",
                attrs: { editor: _vm.editor },
                nativeOn: {
                  keyup: function ($event) {
                    return _vm.handleKeyUp.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _vm.suffix_icon
            ? _c("div", { staticClass: "suffix-icon" }, [
                _c("i", { class: _vm.suffix_icon }),
              ])
            : _vm._e(),
          _vm.has_append_slot
            ? _c("div", { staticClass: "append" }, [_vm._t("append")], 2)
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }