var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "command-layout",
    {
      attrs: { show_back: true },
      on: {
        goBack: function ($event) {
          return _vm.$emit("goBack")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "header", attrs: { slot: "header" }, slot: "header" },
        [
          _c("todo-input", {
            ref: "input",
            attrs: { content: _vm.shared_query },
            on: {
              cancel: function ($event) {
                return _vm.$emit("goBack")
              },
            },
          }),
        ],
        1
      ),
      _c("todo-list", { attrs: { slot: "body" }, slot: "body" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }