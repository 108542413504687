var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.items
    ? _c("div", { staticClass: "loading" }, [
        _c("i", { staticClass: "el-icon-loading" }),
      ])
    : _vm.items.length
    ? _c("div", { staticClass: "list" }, [
        _c(
          "ul",
          _vm._l(_vm.reduced_items, function (item) {
            return _c(
              "li",
              { key: item.id },
              [
                _c(_vm.component, {
                  tag: "component",
                  staticClass: "suggestion-item",
                  attrs: {
                    data: item,
                    is_selected: _vm.selected_item === item.id,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectItem(item)
                    },
                    mouseenter: function ($event) {
                      return _vm.hoverItem(item)
                    },
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }