var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { staticClass: "skip", class: { loading: _vm.loading } },
    [
      _vm.loading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: true,
                expression: "true",
              },
            ],
            staticClass: "spinner",
          })
        : _vm._e(),
      _c("svgicon", { attrs: { name: "skip" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }