var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "dot",
    style: {
      backgroundColor: _vm.color,
      animation: _vm.animated ? null : "none",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }