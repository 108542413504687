var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "avatar-wrapper",
      class: _vm.size,
      attrs: {
        content: _vm.user.name,
        placement: "bottom",
        disabled: _vm.hide_tooltip,
        "popper-class": "avatar-tooltip",
      },
    },
    [
      _c("div", [
        _vm.live
          ? _c(
              "div",
              { staticClass: "dot-container" },
              [
                _c("live-dot", {
                  attrs: { animated: !_vm.color, color: _vm.color },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "avatar" }, [
          _vm.show_initials
            ? _c("span", { staticClass: "initials" }, [
                _vm._v(" " + _vm._s(_vm.custom_initials || _vm.initials) + " "),
              ])
            : _vm.image
            ? _c("img", { attrs: { src: _vm.image, draggable: "false" } })
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }