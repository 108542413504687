var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tracking-block content" },
    [
      _vm.is_tracking || _vm.is_loading
        ? _c("stop-button", {
            directives: [
              {
                name: "only-super-admin",
                rawName: "v-only-super-admin",
                value: !_vm.show_stop_button,
                expression: "!show_stop_button",
              },
            ],
            attrs: { loading: _vm.stopping || _vm.is_loading },
            nativeOn: {
              click: function ($event) {
                return _vm.handleStopAllTimers.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "info" },
        [
          _vm.is_tracking || _vm.is_loading
            ? _c("component-info", { attrs: { component: _vm.component } })
            : _c("span", { staticClass: "label" }, [_vm._v("Not tracking")]),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.session
          ? _c("span", { key: _vm.cube.active_face, staticClass: "time" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("milli2duration")(_vm.tspent__active_sessions)
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.session
          ? _c(
              "div",
              { key: _vm.cube.active_face, staticClass: "note desktop-only" },
              [
                _vm.is_current_user
                  ? [_c("span", [_vm._v(" What are you working on? ")])]
                  : _vm._e(),
                _c("session-input", {
                  staticClass: "el-input session-input",
                  attrs: {
                    placeholder: _vm.is_current_user ? "Add note" : null,
                    editable: _vm.editable,
                    session: _vm.session,
                  },
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }