var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "suggestion-list",
    _vm._b(
      {
        ref: "list",
        attrs: {
          items: _vm.items,
          component: _vm.component,
          "client-rect": _vm.client_rect,
        },
      },
      "suggestion-list",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }