var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "todo-item-view" },
    [
      _c("todo-checkbox", {
        staticClass: "checkbox",
        attrs: {
          id: _vm.todo_id,
          checked: _vm.is_checked,
          actual_priority: _vm.priority,
        },
        on: {
          on_change: _vm.toggleStatus,
          priority_changed: _vm.handlePriorityChange,
        },
      }),
      _c(
        "div",
        {
          staticClass: "content",
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }