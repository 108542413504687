var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "placeholder",
      attrs: { src: "@/assets/img/search.png", draggable: "false" },
    },
    [
      _c("search-empty", { staticClass: "image" }),
      _vm._t("title"),
      _vm._t("subtitle"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }