var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "issue-item", on: { click: _vm.openItem } },
    [
      _vm.show_resource
        ? _c(
            "el-tag",
            { staticClass: "resource", attrs: { type: "info", size: "small" } },
            [_vm._v(" " + _vm._s(_vm.item.resource.name) + " ")]
          )
        : _vm._e(),
      _vm.show_project
        ? _c(
            "el-tag",
            { staticClass: "project", attrs: { type: "info", size: "small" } },
            [_vm._v(" " + _vm._s(_vm.item.jira_project.label) + " ")]
          )
        : _vm._e(),
      _c("div", { staticClass: "type" }, [
        _c("img", {
          staticClass: "type_icon",
          attrs: { alt: _vm.item.type.label, src: _vm.item.type.icon },
          on: { error: _vm.imgError },
        }),
      ]),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "issue-container" },
          [
            _c(
              "el-tooltip",
              { attrs: { content: _vm.item.priority.label, placement: "top" } },
              [
                _c("img", {
                  staticClass: "priority_icon",
                  attrs: {
                    alt: _vm.item.priority.label,
                    src: _vm.item.priority.icon,
                  },
                }),
              ]
            ),
            _c("span", { staticClass: "key" }, [_vm._v(_vm._s(_vm.item.key))]),
          ],
          1
        ),
        _c("span", { staticClass: "summary" }, [
          _vm._v(_vm._s(_vm.item.summary)),
        ]),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.show_status
              ? _c("el-tag", { attrs: { type: _vm.type, size: "mini" } }, [
                  _vm._v(" " + _vm._s(_vm.item.status.label) + " "),
                ])
              : _vm._e(),
            _vm.item.assignee && _vm.show_assignee
              ? _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.item.assignee.name,
                      placement: "top",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "assignee",
                      attrs: { src: _vm.item.assignee.avatar },
                    }),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "info" }, [
        _vm.item.parent
          ? _c(
              "div",
              { staticClass: "component", on: { click: _vm.openParentItem } },
              [
                _vm.item.parent.type === "Epic"
                  ? _c("span", [
                      _vm._v(" " + _vm._s(_vm.item.parent.summary) + " "),
                    ])
                  : _c("span", [
                      _vm._v("Subtask of " + _vm._s(_vm.item.parent.key)),
                    ]),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _vm.item.due_date
            ? _c(
                "div",
                { staticClass: "date due-by", class: _vm.date_priority },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("relativeDate")(_vm.due_date.toDate())) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.item.estimate
            ? _c("div", { staticClass: "estimate" }, [
                _vm._v(" " + _vm._s(_vm.estimate_hours) + "h "),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "spacer desktop-only" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }