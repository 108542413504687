var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "link",
      style: _vm.computed_style,
      attrs: { href: _vm.link, target: "_blank" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return (() => null).apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.stored_issue
            ? [
                _c("img", {
                  staticClass: "type-icon",
                  attrs: {
                    alt: _vm.stored_issue.type.label,
                    src: _vm.stored_issue.type.icon,
                  },
                }),
                _c("span", { staticClass: "ticket_id" }, [
                  _vm._v(_vm._s(_vm.ticket_id)),
                ]),
                _vm.show_summary
                  ? _c("span", { staticClass: "summary" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("truncate")(_vm.stored_issue.summary, 50)
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "el-tag",
                  {
                    staticClass: "status",
                    attrs: { type: _vm.status_color, size: "mini" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.stored_issue.status.label.toUpperCase()) +
                        " "
                    ),
                  ]
                ),
              ]
            : _c("span", { staticClass: "ticket_id" }, [
                _vm._v(" " + _vm._s(_vm.ticket_id) + " "),
              ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }