var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("label", [_vm._v("Calendar")]),
    _c(
      "div",
      { staticClass: "right" },
      [
        _vm.loading
          ? _c("span", [_vm._v(" Syncing... ")])
          : _vm.calendar_total_sync
          ? _c("span", [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(_vm.calendar_total_sync) +
                    " " +
                    _vm._s(
                      _vm._f("pluralize")("event", _vm.calendar_total_sync)
                    ) +
                    " "
                ),
              ]),
              _vm._v(" updated "),
            ])
          : _vm.system_calendars
          ? _c("span", { staticClass: "last-updated" }, [
              _vm._v(
                " Last updated " +
                  _vm._s(
                    _vm._f("timeAgo")(
                      _vm._f("timestamp2moment")(
                        _vm.system_calendars.last_updated?.seconds
                      )
                    )
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
        _c("el-button", {
          staticStyle: { width: "100%" },
          attrs: {
            disabled: _vm.loading,
            icon: "el-icon-refresh",
            loading: _vm.loading,
          },
          on: { click: _vm.sync },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }