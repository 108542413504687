var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("avatar", {
            staticClass: "avatar",
            attrs: { user: _vm.user, size: "large", "hide-tooltip": "" },
          }),
          _c("file-uploader", {
            attrs: { folder: "avatars", max_file_size_bytes: 500 * 1024 },
            on: { uploaded: (val) => _vm.update("avatar", val) },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("label", [_vm._v("Personal color")]),
          _c("color-picker", {
            attrs: { value: _vm.color, show_dialog: _vm.show_dialog },
            on: { input: (val) => _vm.update("color", val) },
          }),
        ],
        1
      ),
      _c("user-policy-settings", { attrs: { user: _vm.user } }),
      !_vm.$store.getters.is_feature_enabled(_vm.SUPPORTED_FEATURES.GOOGLE_AUTH)
        ? _c("div", { staticClass: "container" }, [
            _c("label", [_vm._v("Password")]),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$bus.$emit("modal:action", {
                          modal: "change-password",
                          show: true,
                        })
                      },
                    },
                  },
                  [_vm._v(" Change Password ")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }