var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "link",
      attrs: { href: "javascript:void(0)" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _vm.ref
        ? _c(
            "el-tag",
            { staticClass: "ref", attrs: { size: "mini", type: "info" } },
            [_vm._v(" " + _vm._s(_vm.ref) + " ")]
          )
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.content) + " "),
      _vm.status
        ? _c(
            "el-tag",
            {
              staticClass: "status",
              attrs: { size: "mini", type: _vm.status.type },
            },
            [_vm._v(" " + _vm._s(_vm.status.label) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }