var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    [
      _c(
        "div",
        { staticClass: "medium_wrapper" },
        [
          _c(
            "div",
            {
              staticClass: "logo-wrapper",
              class: { "has-org-logo": _vm.has_org_logo },
              on: { click: _vm.navigateToHome },
            },
            [
              _c("svgicon", {
                staticClass: "logo",
                attrs: { name: "logo-text" },
              }),
              _vm.has_org_logo
                ? _c("org-logo", {
                    attrs: { url: _vm.$store.state.organisation.logo },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-menu",
            {
              staticClass: "desktop-only",
              attrs: { "default-active": _vm.active_index, mode: "horizontal" },
            },
            _vm._l(_vm.items, function (i) {
              return _c(
                "el-menu-item",
                {
                  directives: [
                    {
                      name: "only-super-admin",
                      rawName: "v-only-super-admin",
                      value: i.private,
                      expression: "i.private",
                    },
                  ],
                  key: i.path,
                  attrs: { index: i.path },
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: i.path, draggable: "false" } },
                    [_vm._v(" " + _vm._s(i.label) + " ")]
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "right desktop-only" },
            [
              _c("search-input"),
              _c(
                "a",
                {
                  staticClass: "notification-icon",
                  on: { click: _vm.handleViewNotifications },
                },
                [
                  _c(
                    "el-badge",
                    {
                      staticClass: "badge",
                      attrs: {
                        hidden: !_vm.unread_notification_count,
                        value: _vm.unread_notification_count,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-bell" })]
                  ),
                  _vm.show_notifications
                    ? _c("notification-list", {
                        attrs: { notifications: _vm.notifications },
                        on: {
                          close: _vm.handleCloseNotifications,
                          click: _vm.handleNavigateNotification,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a",
                {
                  staticClass: "profile-icon",
                  on: { click: _vm.handleUserProfile },
                },
                [_c("svgicon", { attrs: { name: "settings" } })],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "right mobile-only" }, [
            _c(
              "a",
              { staticClass: "menu-icon", on: { click: _vm.toggleMenu } },
              [_c("svgicon", { attrs: { name: "menu" } })],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-menu",
        {
          staticClass: "mobile-only",
          style: {
            height: _vm.show_menu ? `${_vm.mobile_items.length * 56}px` : "0",
          },
          attrs: { "default-active": _vm.active_index, mode: "vertical" },
        },
        _vm._l(_vm.mobile_items, function (i) {
          return _c(
            "el-menu-item",
            {
              directives: [
                {
                  name: "only-super-admin",
                  rawName: "v-only-super-admin",
                  value: i.private,
                  expression: "i.private",
                },
              ],
              key: i.path,
              attrs: { index: i.path },
            },
            [
              _c("router-link", { attrs: { to: i.path, draggable: "false" } }, [
                _vm._v(" " + _vm._s(i.label) + " "),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }